<template>
  <Error v-if="error" />
  <div id="index" ref="index" v-else>
    <section class="flex flex-col items-center w-full min-h-screen">
      <img class="h-32 md:h-24" src="@/assets/img/logo.svg" alt="Good Way" />
      <div class="container p-10 leading-relaxed">
        <img
          :src="`/storage/blogs/${data.file}`"
          :alt="data.title"
          v-if="data.file"
        />
        <h4
          v-html="data.title"
          class="px-10 pt-5 text-2xl font-extrabold text-center lg:text-4xl"
        ></h4>
        <p v-html="data.text" class="text-lg"></p>
      </div>
      <router-link to="/blog" class="py-4 underline hover:text-primary"
        >Wróć do bloga</router-link
      >
    </section>
    <Copy />
  </div>
</template>

<script>
import axios from "axios";
import Copy from "@/components/Theme/Footer";
import Error from "../../views/Error";

export default {
  name: "Show",
  data() {
    return {
      data: {},
      error: false,
    };
  },
  components: {
    Copy,
    Error,
  },
  watch: {
    $route: "fetchData",
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await axios
        .get("/api/app/blog/" + this.$route.params.name)
        .then((response) => {
          this.data = response.data;
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
};
</script>