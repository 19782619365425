<template>
  <section class="w-full min-h-screen bg-thirdry text-gray-200 flex justify-center items-center">
    <div class="container">
      <h2 class="lg:text-4xl text-xl text-center leading-tight py-2">Error 404</h2>
    </div>
  </section>
</template>

<script>
export default {
  name: "Error"
};
</script>
