<template>
  <div class="w-full bg-thirdry text-gray-200">
    <div class="container mx-auto lg:p-10 px-10 py-5 flex justify-between md:flex-col">
      <div class="font-mono m-2 md:text-center md:w-full">Copyright © 2020 Good Way Life Discovery</div>
      <div class="flex md:flex-col md:justify-center md:w-full items-center">
        <ul class="flex md:justify-center md:w-full">
          <li>
            <a
              href="https://www.facebook.com/goodwaylifediscovery/"
              target="_blank"
              class="text-gray-200 no-underline hover:text-black"
              rel="noopener noreferrer"
            >
              <svg
                class="w-6 m-2 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0"
                y="0"
                viewBox="0 0 600 600"
                xml:space="preserve"
              >
                <title>GOOD WAY Life Discovery</title>
                <path
                  d="M4 564.9C4 388.3 4 211.7 4 35.1c0.3-1.2 0.8-2.3 1-3.5C7.4 17.4 17.4 7.4 31.6 5c1.2-0.2 2.3-0.6 3.5-1 176.6 0 353.2 0 529.8 0 21 5.9 24.9 9.3 31.1 31.1 0 176.8 0 353.6 0 530.3 -7.4 22.6-9.1 23.5-30.6 30.6 -50.8 0-101.6 0-152.4 0 0.1-7.7 0.1 22.9 0.1-229.3 84.1 0 76.7 1.9 77.5-4.7 10.9-91.4 13.2-84.9 7.3-84.9 -31.9 0-58.6 0-84.6 0 0-0.2-1.2-60.1 1.6-73.8 9.1-45.6 89.8-15.8 89.7-29 -0.6-86 5.3-78.3-13.5-79 -54.7-2-125.7-12.7-159 56.5 -14.9 31-12.2 53.7-12.2 125.4 -82.1 0-77.6-1.4-77.6 3.1 0.3 94.6-1.8 86.5 3.4 86.5 22 0 52.1 0 74.2 0 0 71.4 0 158 0 225.6 0 1.2-0.1 2.5-0.1 3.7 -95 0-190.1 0-285.1 0C14.5 589.4 10.4 587.9 4 564.9z"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/krzysztof-janowski-b34a1555/"
              target="_blank"
              class="text-gray-200 no-underline hover:text-black"
              rel="noopener noreferrer"
            >
              <svg
                class="w-6 m-2 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 20 20"
              >
                <title>GOOD WAY Krzysztof Janowski</title>
                <path
                  d="M1,6h4v13H1V6z M3,1C1.8,1,1,2,1,3.1C1,4.1,1.8,5,3,5c1.3,0,2-0.9,2-2C5,1.9,4.2,1,3,1z M14.6,6.2  c-2.1,0-3.3,1.2-3.8,2h-0.1l-0.2-1.7H6.9C6.9,7.6,7,8.9,7,10.4V19h4v-7.1c0-0.4,0-0.7,0.1-1c0.3-0.7,0.8-1.6,1.9-1.6  c1.4,0,2,1.2,2,2.8V19h4v-7.4C19,7.9,17.1,6.2,14.6,6.2z"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCxbEWgpU30VL5_wMyy7S88g"
              target="_blank"
              class="text-gray-200 no-underline hover:text-black"
              rel="noopener noreferrer"
            >
              <svg
                class="w-6 m-2 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0"
                y="0"
                viewBox="0 0 600 600"
                xml:space="preserve"
              >
                <title>GOOD WAY Life Discovery</title>
                <path
                  d="M592.8 215.7c-4.1-56.4-14.5-114.7-87.5-118.9 -133.5-7.7-277.1-7.7-410.6 0 -73 4.2-83.4 62.5-87.5 118.9 -4.3 58.4-4.3 110.1 0 168.6 4.1 56.4 14.5 114.7 87.5 118.9 133.5 7.7 277.1 7.7 410.6 0 73-4.2 83.4-62.5 87.5-118.9C597.1 325.8 597.1 274.2 592.8 215.7zM239.6 377V210.7l158.5 83.2L239.6 377z"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="http://www.instagram.com/krzysztofjanowski"
              target="_blank"
              class="text-gray-200 no-underline hover:text-black"
              rel="noopener noreferrer"
            >
              <svg
                class="w-6 m-2 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0"
                y="0"
                viewBox="0 0 600 600"
                xml:space="preserve"
              >
                <title>Krzysztof Janowski GOOD WAY</title>
                <path
                  d="M420.4 596H179.6C87.4 596 12 520.6 12 428.4V171.6C12 79.4 87.4 4 179.6 4h240.8C512.6 4 588 79.4 588 171.6v256.8C588 520.6 512.6 596 420.4 596zM420.4 551.7c32.7 0 63.7-12.9 87-36.3 23.4-23.4 36.3-54.3 36.3-87.1V171.6c0-32.8-12.9-63.7-36.3-87.1s-54.3-36.3-87-36.3H179.6c-32.7 0-63.7 12.9-87 36.3s-36.3 54.3-36.3 87.1v256.7c0 32.8 12.9 63.7 36.3 87.1 23.4 23.4 54.3 36.3 87 36.3H420.4zM459.5 297.7c0 87.6-71 158.7-158.6 158.7s-158.6-71-158.6-158.7S213.3 139 300.9 139 459.5 210.1 459.5 297.7zM300.1 193.9c-57.4 0-103.9 46.5-103.9 103.9s46.5 103.9 103.9 103.9S404 355.2 404 297.8 357.5 193.9 300.1 193.9z"
                />
              </svg>
            </a>
          </li>
        </ul>
        <router-link
          to="regulamin"
          class="text-gray-200 no-underline hover:p-1 md:text-sm"
        >Regulamin</router-link>
        <router-link
          to="polityka-prywatnosci"
          class="text-gray-200 no-underline hover:p-1 md:text-sm"
        >Polityka prywatności</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Right"
};
</script>
