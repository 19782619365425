<template>
  <footer class="w-full">
    <Right />
  </footer>
</template>

<script>
import Right from "@/components/Theme/Right";

export default {
  name: "Footer",
  components: {
    Right,
  },
};
</script>
