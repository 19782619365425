<template>
  <Show />
</template>

<script>
import Show from "@/components/Blog/Show";

export default {
  name: "Blog",
  components: {
    Show
  }
};
</script>
